import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import '../../../static/styles/fontawesome-5.15.2/css/fontawesome.min.css'
import { useIntl, Link } from '@intractive/gatsby-plugin-react-intl'
import * as style from "./index.module.css"

const PageCanIVote = ({ data }) => {
  const intl = useIntl(); 
  return (
    <Layout>
      <Helmet>
        <title>{intl.formatMessage({id: "Can I Vote?"})} · STIP</title>
      </Helmet>
      <div className="row main-content">
        <div className="large-12 medium-12 small-12 columns">
            <center><img className="large-6 medium-8" alt="Can I Vote in these elections? Do you officially live in Delft? Are you an EU citizen OR have you lived in the Netherlands for five consecutive years? If the answer to both of these questions is YES, then you can vote on STIP in Delft on the 14th, 15th and 16th of March. "src="/images/canivote.png" /></center>

            <h2>What does ‘living officially in Delft’ mean?</h2>
            <p>Living officially in Delft means that you are registered with the ‘Gemeente Delft’. If this is the case, you should have received your ‘Stempas’ (voting pass).</p>

            <center>
            <img src="https://images.ctfassets.net/xr7ntapvnai4/2WLLZuMTPGyiMicD24Hojk/6a912f2a432abf34023be7d633ad42e5/signal-2022-03-03-151248_001.jpeg?h=500" className="large-4 medium-6" alt="Image of a voting pass" />
            </center>

            <p>If you have received your voting pass, you can definitely vote on the 14th, 15th and 16th of March!</p>

            <p>If you are registered in Delft, but haven’t received your voting pass yet, you have until the 11th of March to request your voting pass. You can make an appointment to request your voting pass by calling <b>14015</b> or <a href="https://www.delft.nl/idd/afspraak" rel="nofollow">by requesting an appointment online.</a>
    </p>

            <h2>Where can you vote?</h2>
            <p>
                On the 14th, 15th and 16th of March, many polling places will pop-up in Delft. <a href="https://www.delft.nl/bestuur-en-organisatie/verkiezingen/stembureaus-delft">You can find a list of all the places here.</a>
            </p>

            <h2>What do you need to bring on the days of elections?</h2>
            <p>On the days of elections, you should bring your voting pass and your ID card or passport. You will get the election list and pencil at the polling location. </p>


        </div>
      </div>
      <center><b><Link to="/">Learn more about STIP ⟶</Link></b></center>
    </Layout>
  )
}

export default PageCanIVote
